import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {useImageContext} from "../context/ImageContext";
import Masonry from 'react-masonry-css';
import fjGallery from "flickr-justified-gallery";
import {Box, Center, Spinner} from "@chakra-ui/react";

import "./service.css"

const serviceData = {
    wedding: {
        title: "Wedding Photography",
        description: "Capture the magic of your special day with our exquisite wedding photography. We weave your love story into timeless images that you’ll cherish forever.",
        imageKey: "weddingImagesCache",
    },
    prewedding: {
        title: "Pre Wedding Photography",
        description: "Celebrate your journey to ‘I do’ with our enchanting pre-wedding photography. We capture the romance and excitement leading up to your big day.",
        imageKey: "preweddingImagesCache",
    },
    candid: {
        title: "Candid Wedding Photography",
        description: "Discover the beauty in the moments you didn’t even know were happening. Our candid photography captures genuine emotions and spontaneous smiles.",
        imageKey: "candidImagesCache",
    },
    "rice-ceremony": {
        title: "Rice Ceremony Photography",
        description: "Preserve the sacred traditions of your rice ceremony with our heartfelt photography. We honor every ritual, capturing the essence of your heritage.",
        imageKey: "riceCeremonyImagesCache",
    },
    maternity: {
        title: "Maternity & Baby Photography",
        description: "Celebrate the joy of new beginnings with our maternity photography. We create stunning portraits that capture the beauty and anticipation of motherhood.",
        imageKey: "maternityImagesCache",
    },
    birthday: {
        title: "Birthday Photography",
        description: "Turn your birthday celebrations into lasting memories with our vibrant birthday photography. From joyous gatherings to heartfelt moments, we capture it all.",
        imageKey: "birthdayImagesCache",
    },
};

const Services = () => {
    const { serviceId } = useParams();
    const service = serviceData[serviceId];

    const { images, imageCache, loading } = useImageContext();

    useEffect(() => {
        if (!loading && images[service.imageKey]?.length > 0) {
            fjGallery(document.querySelectorAll('.gallery'), {
                itemSelector: '.gallery__item',
                rowHeight: 300,
                lastRow: 'start',
                gutter: 2,
                rowHeightTolerance: 0.1,
                calculateItemsHeight: true,
            });
        }
    }, [loading, images, service.imageKey]);


    if (loading) {
        return (
            <Box className="w-screen h-full">
                <Center h="100vh">
                    <Spinner size="xl" color="blue.500" />
                </Center>
            </Box>
        );
    }

    const serviceImages = imageCache[service.imageKey]?.length > 0
        ? imageCache[service.imageKey].map(img => img.src)
        : images[service.imageKey.replace("Cache", "")];

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
    };

    return (
        <div className="bg-background text-foreground w-full">
            <div className="relative">
                <img
                    src="https://foreverknots.s3.eu-north-1.amazonaws.com/uploads/1720267219-340974846_203272749082629_8514117522410754521_n (4).jpg"
                    alt="Photography Gallery"
                    width="1920"
                    height="1080"

                    className="w-full h-[400px] md:h-[500px] object-cover"
                />
                <div
                    className="absolute inset-0 bg-gradient-to-t from-background/80 to-background/0 flex items-center justify-center">
                    <div className="text-center text-white space-y-4">
                        <h1 className="text-3xl md:text-4xl font-bold text-foreground">Welcome to {service.title}</h1>
                        <p className="text-muted-foreground max-w-md">
                            Explore our stunning collection of photographs capturing the beauty of life's special
                            moments.
                        </p>
                    </div>
                </div>
            </div>
            <div className={"max-w-screen"}>
                <section className="py-12 w-full md:py-16 ">
                    <div className="w-full  lg:p-2 ">
                        <div className="space-y-6">
                            <div className={"p-2"}>
                                <h2 className="lg:text-3xl md:text-4xl text-2xl font-bold text-primary-foreground">{service.title}</h2>
                                <p className=" mx-auto font-Jost lg:text-[20px] text-[18px]">
                                    {service.description}
                                </p>
                            </div>
                            <Box className="w-full h-full lg:p-8">
                                {serviceImages.length === 0 ? (
                                    <Box textAlign="center" mt={8}>
                                        <p>No images found in the gallery.</p>
                                    </Box>
                                ) : (
                                    <Masonry
                                        breakpointCols={breakpointColumnsObj}
                                        className="my-masonry-grid"
                                        columnClassName="my-masonry-grid_column">
                                        {serviceImages.map((image, index) => (
                                            <div key={index}>
                                                <img className="w-full" src={image} alt={`Gallery ${index + 1}`} />
                                            </div>
                                        ))}
                                    </Masonry>
                                )}
                            </Box>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Services;
