
import Testi1 from "../assets/testimonials/412640555_267182229700191_7796196750634493288_n.jpg"
import BGImg from "../assets/testimonials/340802420_1796060444122181_5450486197515292187_n.jpg"
import Modal from "../components/Modal/Modal";
import {CiCalendar} from "react-icons/ci";
import React, {useState} from "react";

export default function Component() {
    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    return (
        <div className="flex flex-col items-center justify-center min-h-[100dvh]">
            <section className="w-screen flex flex-col items-center justify-center py-12 md:py-24 lg:py-20 relative">
                <img
                    src={BGImg}
                    alt="Hero"
                    className="absolute inset-0 lg:h-[400px] h-[200px] w-full  object-cover  opacity-80"
                />
                <div className="container lg:w-[40%] flex flex-col justify-end items-center relative z-10 lg:top-28 lg:left-[30%]  top-28 px-4 md:px-6 text-center lg:space-y-6">
                    <h1 className="text-xl md:text-5xl lg:text-4xl  font-Jost text-black">
                        Capturing the Moments That Matter
                    </h1>
                    <p className="lg:max-w-[700px]  lg:block hidden  text-[12px] md:text-xl font-Roboto">
                        At ForeverKnots, we believe that every wedding is a unique and beautiful story
                        waiting to be
                        told. Our mission is to capture the authentic moments, the genuine emotions, and the timeless
                        connections
                        that make your day truly special.
                    </p>
                </div>
            </section>
            <section className="w-screen lg:mt-0 mt-10 flex flex-row items-center justify-center py-12 md:py-24 lg:py-20 bg-muted">
                <div className="container px-4 md:px-6 grid gap-12 lg:grid-cols-2 lg:gap-16">
                    <div className="space-y-4">
                        <h2 className="text-3xl md:text-4xl font-bold text-primary-foreground">Our Approach</h2>
                        <p className=" font-Jost md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                            At ForeverKnots, we believe that wedding photography is about more than just
                            capturing
                            beautiful images. It's about telling a story, preserving the memories, and creating art that
                            will be
                            cherished for generations to come.
                        </p>
                        <p className=" font-Jost md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                            Our approach is rooted in a deep understanding of light, emotion, and the unique energy of
                            each wedding
                            day. We work closely with our clients to understand their vision and tailor our style to
                            perfectly
                            complement their personalities and the overall aesthetic of their event.
                        </p>
                        <div className="flex flex-wrap gap-4">
                            <div
                                className="inline-flex w-fit items-center whitespace-nowrap rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80"
                                data-v0-t="badge"
                            >
                                Award-Winning
                            </div>
                            <div
                                className="inline-flex w-fit items-center whitespace-nowrap rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80"
                                data-v0-t="badge"
                            >
                                Artistic
                            </div>
                            <div
                                className="inline-flex w-fit items-center whitespace-nowrap rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80"
                                data-v0-t="badge"
                            >
                                Authentic
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <img
                            src="https://foreverknots.s3.eu-north-1.amazonaws.com/uploads/1720268229-357724438_166709903080758_8355647151455208897_n.jpg"

                            alt="Wedding  1"
                            className="aspect-[4/3] overflow-hidden rounded-lg object-cover"
                        />
                        <img
                            src="https://foreverknots.s3.eu-north-1.amazonaws.com/uploads/1720268371-1719463993455.jpg"

                            alt="Wedding  2"
                            className="aspect-[4/3] overflow-hidden rounded-lg object-cover"
                        />
                        <img
                            src="https://foreverknots.s3.eu-north-1.amazonaws.com/uploads/1720268408-1716891166961.jpg"

                            alt="Wedding  3"
                            className="aspect-[4/3] overflow-hidden rounded-lg object-cover"
                        />
                        <img
                            src="https://foreverknots.s3.eu-north-1.amazonaws.com/uploads/1720268478-1712803647810 (1).jpg"

                            alt="Wedding  4"
                            className="aspect-[4/3] overflow-hidden rounded-lg object-cover"
                        />
                    </div>
                </div>
            </section>
            <section className="w-screen flex flex-col items-center justify-center py-12 md:py-24 lg:py-20">
                <div className="container px-4 md:px-6">
                    <div className="space-y-6">
                        <div className="space-y-2 text-center">
                            <div className="inline-block rounded-lg bg-muted px-3 py-1 text-sm">Gallery</div>
                            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                                Our Wedding Photography Portfolio
                            </h2>
                            <p className="max-w-[700px] mx-auto font-Jost md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                                Explore a selection of our favorite wedding photos, showcasing our unique style and
                                attention to detail.
                            </p>
                        </div>
                        <div
                            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-center justify-center gap-4">
                            <img
                                src="https://foreverknots.s3.eu-north-1.amazonaws.com/uploads/1720268229-357724438_166709903080758_8355647151455208897_n.jpg"

                                alt="Wedding  1"
                                className="aspect-[4/3] overflow-hidden rounded-lg object-cover"
                            />
                            <img
                                src="https://foreverknots.s3.eu-north-1.amazonaws.com/uploads/1720268371-1719463993455.jpg"

                                alt="Wedding  2"
                                className="aspect-[4/3] overflow-hidden rounded-lg object-cover"
                            />
                            <img
                                src="https://foreverknots.s3.eu-north-1.amazonaws.com/uploads/1720268408-1716891166961.jpg"

                                alt="Wedding  3"
                                className="aspect-[4/3] overflow-hidden rounded-lg object-cover"
                            />
                            <img
                                src="https://foreverknots.s3.eu-north-1.amazonaws.com/uploads/1720268478-1712803647810 (1).jpg"

                                alt="Wedding  4"
                                className="aspect-[4/3] overflow-hidden rounded-lg object-cover"
                            />
                            <img
                                src="https://foreverknots.s3.eu-north-1.amazonaws.com/uploads/1720500253-370277238_198193626599052_6238673340175541713_n.jpg"

                                alt="Wedding  5"
                                className="aspect-[4/3] overflow-hidden rounded-lg object-cover"
                            />
                            <img
                                src="https://foreverknots.s3.eu-north-1.amazonaws.com/uploads/1720268183-368229236_193352880416460_5894480636005709621_n.jpg"

                                alt="Wedding  6"
                                className="aspect-[4/3] overflow-hidden rounded-lg object-cover"
                            />
                        </div>
                    </div>
                </div>
            </section>
            {/*Testimonials*/}
            <section className=" py-12 md:py-24 lg:py-20">
                <div className="container px-4 md:px-6 space-y-12">
                    <div className="text-center space-y-4">
                        <h2 className="text-3xl md:text-4xl font-bold text-primary-foreground">What Our Clients Say</h2>
                        <p className="max-w-[700px] mx-auto text-muted-foreground">
                            Hear from some of our happy clients about their experience working with ForeverKnots.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        <div className="border text-card-foreground shadow-sm p-6 bg-muted rounded-lg" data-v0-t="card">
                            <div className="flex items-center gap-4 mb-4">
            <span className="relative flex h-16 w-16 shrink-0 overflow-hidden rounded-full">
              <img className="aspect-square h-full w-full" src={Testi1} alt="Testimonial"/>
            </span>
                                <div>
                                    <p className="text-lg font-medium text-primary-foreground">Jessica Doe</p>
                                    <p className="text-sm text-muted-foreground">Married in 2022</p>
                                </div>
                            </div>
                            <p className="text-muted-foreground">
                                "ForeverKnots Photography captured our wedding day perfectly. They were
                                professional, creative,
                                and truly captured the essence of our love. We're so grateful to have these
                                beautiful memories
                                to cherish forever."
                            </p>
                        </div>
                        <div className="border text-card-foreground shadow-sm p-6 bg-muted rounded-lg" data-v0-t="card">
                            <div className="flex items-center gap-4 mb-4">
                                <span className="relative flex h-16 w-16 shrink-0 overflow-hidden rounded-full">
                                  <img className="aspect-square h-full w-full" src={Testi1} alt="Testimonial"/>
                                </span>
                                <div>
                                    <p className="text-lg font-medium text-primary-foreground">Jessica Doe</p>
                                    <p className="text-sm text-muted-foreground">Married in 2022</p>
                                </div>
                            </div>
                            <p className="text-muted-foreground">
                                "ForeverKnots Photography captured our wedding day perfectly. They were
                                professional, creative,
                                and truly captured the essence of our love. We're so grateful to have these
                                beautiful memories
                                to cherish forever."
                            </p>
                        </div>
                        <div className="border text-card-foreground shadow-sm p-6 bg-muted rounded-lg" data-v0-t="card">
                            <div className="flex items-center gap-4 mb-4">
           <span className="relative flex h-16 w-16 shrink-0 overflow-hidden rounded-full">
              <img className="aspect-square h-full w-full" src={Testi1} alt="Testimonial"/>
            </span>
                                <div>
                                    <p className="text-lg font-medium text-primary-foreground">Jessica Doe</p>
                                    <p className="text-sm text-muted-foreground">Married in 2022</p>
                                </div>
                            </div>
                            <p className="text-muted-foreground">
                                "ForeverKnots Photography captured our wedding day perfectly. They were
                                professional, creative,
                                and truly captured the essence of our love. We're so grateful to have these
                                beautiful memories
                                to cherish forever."
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <button
                            onClick={handleOpenModal}
                            className="border border-black flex flex-row items-center justify-center gap-3 rounded-[25px] font-Jost lg:text-[16px] text-[14px] font-[500] lg:pl-[30px] lg:pr-[30px] lg:pt-[15px] lg:pb-[15px] pl-[20px] pr-[20px] pt-[10px] pb-[10px] hover:bg-black hover:text-white transition duration-300"
                        >
                            <CiCalendar size={25}/> Book Your Session
                        </button>
                    </div>
                </div>
            </section>
            <Modal show={showModal} onClose={handleCloseModal} />
        </div>
    )
}