// GalleryComponent.js
import React, { useEffect } from 'react';
import { Box, Center, Spinner } from '@chakra-ui/react';
import './style.css';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import fjGallery from 'flickr-justified-gallery';
import { useImageContext } from '../../context/ImageContext';

const GalleryComponent = () => {
    const { images, imageCache, loading } = useImageContext();
    console.log(imageCache,images)

    useEffect(() => {
        if (!loading && images.gallery_images.length > 0) {
            fjGallery(document.querySelectorAll('.gallery'), {
                itemSelector: '.gallery__item',
                rowHeight: 300,
                lastRow: 'start',
                gutter: 2,
                rowHeightTolerance: 0.1,
                calculateItemsHeight: true,
            });
        }
    }, [loading, images]);

    if (loading) {
        return (
            <Box className="w-screen h-full">
                <Center h="100vh">
                    <Spinner size="xl" color="blue.500" />
                </Center>
            </Box>
        );
    }

    const galleryImages = imageCache.galleryImagesCache.length > 0
        ? imageCache.galleryImagesCache.map(img => img.src)
        : images.gallery_images;

    return (
        <Box className="w-screen h-full">
            {galleryImages.length === 0 ? (
                <Box textAlign="center" mt={8}>
                    <p>No images found in the gallery.</p>
                </Box>
            ) : (
                <LightGallery
                    plugins={[lgZoom, lgVideo]}
                    mode="lg-fade"
                    pager={false}
                    thumbnail={false}
                    galleryId={'nature'}
                    autoplayFirstVideo={false}
                    elementClassNames={'gallery'}
                    mobileSettings={{
                        controls: false,
                        showCloseIcon: true,
                        download: true,
                        rotate: false,
                    }}
                    scale={1.2}
                >
                    {galleryImages.map((image, index) => (
                        <a key={index} className="gallery__item" data-src={image} href={image}>
                            <img className="img-responsive" src={image} alt={`Gallery ${index + 1}`} />
                        </a>
                    ))}
                </LightGallery>
            )}
        </Box>
    );
};

export default GalleryComponent;
