"use client";

import React, { useEffect, useState } from "react";
import { InfiniteMovingCards } from "../MovingCard/MovingCards";
import ProfileImg from "../../assets/testimonials/412640555_267182229700191_7796196750634493288_n.jpg"

export function InfiniteMovingCardsDemo() {
    return (
        <div className="h-[25rem] w-screen rounded-md flex flex-col antialiased bg-white text-black  items-center justify-center relative overflow-hidden">
            <InfiniteMovingCards
                items={testimonials}
                direction="right"
                speed="slow"
            />
        </div>
    );
}

const testimonials = [
    {
        quote:
            "ForeverKnots Photography captured our wedding day perfectly. They were professional, creative, and truly captured the essence of our love. We're so grateful to have these beautiful memories to cherish forever.",
        name: "Charles Dickens",
        title: "A Tale of Two Cities",
        profile:ProfileImg,
    },
    {
        quote:
            "ForeverKnots Photography captured our wedding day perfectly. They were professional, creative, and truly captured the essence of our love. We're so grateful to have these beautiful memories to cherish forever.",
        name: "William Shakespeare",
        title: "Hamlet",
        profile:ProfileImg,
    },
    {
        quote: "ForeverKnots Photography captured our wedding day perfectly. They were professional, creative, and truly captured the essence of our love. We're so grateful to have these beautiful memories to cherish forever.",
        name: "Edgar Allan Poe",
        title: "A Dream Within a Dream",
        profile:ProfileImg,
    },
    {
        quote:
            "ForeverKnots Photography captured our wedding day perfectly. They were professional, creative, and truly captured the essence of our love. We're so grateful to have these beautiful memories to cherish forever.",
        name: "Jane Austen",
        title: "Pride and Prejudice",
        profile:ProfileImg,
    },
    {
        quote:
            "ForeverKnots Photography captured our wedding day perfectly. They were professional, creative, and truly captured the essence of our love. We're so grateful to have these beautiful memories to cherish forever.",
        name: "Herman Melville",
        title: "Moby-Dick",
        profile:ProfileImg,
    },
];
