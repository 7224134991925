import React, { useState, useEffect } from "react";
import Slider from "../components/Core/Slider";
import Re1 from "../assets/recent/r1.webp";
import Re2 from "../assets/recent/r2.webp";
import Re3 from "../assets/recent/369165545_195729953512086_8991720664710522802_n.webp";
import uniq from "../assets/recent/check.webp";
import { CiCalendar } from "react-icons/ci";
import Modal from "../components/Modal/Modal";
import { Link } from "react-router-dom";
import PhotographyServices from "../components/Services/PhotographyServices";
import Loader from "../components/Core/Loader";
import {CardHoverEffectDemo} from "../components/CardHover/CardGroup";
import {LayoutGridDemo} from "../components/Services/PhotoGraphyServices2";
import {InfiniteMovingCardsDemo} from "../components/Testimonials/Testimonials"; // Import the Loader component

const Home = () => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const hasVisited = localStorage.getItem('hasVisited');

        if (!hasVisited) {
            const timer = setTimeout(() => {
                setLoading(false);
                localStorage.setItem('hasVisited', 'true');
            }, 1000);

            return () => clearTimeout(timer);
        } else {
            setLoading(false);
        }
    }, []);
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    if (loading) {
        return <Loader/>;
    }

    return (
        <div className="flex w-screen flex-col items-center justify-between gap-8">
            <div className="w-screen relative">
                <Slider/>
            </div>

            {/* Recent Weddings */}
            <div
                className="w-full gap-[4%] flex lg:flex-row md:flex-row flex-col items-center justify-center pl-2 pr-2">
                <div className="lg:w-[24%] flex flex-col items-center justify-center p-6 lg:gap-6 gap-10 lg:pl-16">
                    <h2 className="font-Cormorant lg:text-[40px] text-[25px] text-black">
                        RECENT WEDDINGS
                    </h2>
                    <p className="font-Roboto font-[400] lg:text-[18px] text-[14px] text-center tracking-wider">
                        In this portfolio, you’ll find more than just photographs; you’ll
                        discover a visual storytelling journey. Each image is a testament to
                        the love shared between couples, families, and friends on their
                        special day.
                    </p>
                    <Link to={"portfolio"}>
                        <button
                            className="border border-black rounded-[25px] font-Jost lg:text-[18px] text-[12px] font-[500] pl-[30px] pr-[30px] pt-[15px] pb-[15px] hover:bg-black hover:text-white transition duration-300">
                            EXPLORE PORTFOLIO
                        </button>
                    </Link>
                </div>

                <div
                    className="lg:w-[65%] lg:gap-0 gap-6 flex lg:flex-row md:flex-row flex-col items-center justify-around lg:pr-2">
                    <div className="flex items-center justify-center">
                        <div className="relative group w-full">
                            <img
                                className="transition-transform duration-300 transform group-hover:scale-105 lg:h-[500px] lg:w-[350px]"
                                src={Re1}
                                alt="recent r1"
                            />
                            <div
                                className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                            <div
                                className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <span className="text-white text-lg">Your Text Here</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <div className="relative group w-full">
                            <img
                                className="transition-transform duration-300 transform group-hover:scale-105 lg:h-[500px] lg:w-[350px]"
                                src={Re2}
                                alt="recent r2"
                            />
                            <div
                                className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                            <div
                                className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <span className="text-white text-lg">Your Text Here</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <div className="relative group h-[500px]">
                            <img
                                className="transition-transform duration-300 transform group-hover:scale-105 lg:h-[500px] lg:w-[350px]"
                                src={Re3}
                                alt="recent r3"
                            />
                            <div
                                className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                            <div
                                className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <span className="text-white text-lg">Your Text Here</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Your Story is Unique */}
            <div className="bg-[#F2F0EC] flex lg:flex-row flex-col items-center justify-between w-full lg:p-16 p-10 gap-10">
                <div
                    className="lg:w-[50%] flex lg:items-end lg:justify-end items-center justify-center lg:pr-10 lg:pt-0 pt-10">
                    <img
                        className="lg:h-[50%] lg:w-[50%] rounded-md"
                        src={uniq}
                        alt="Unique section"
                    />
                </div>
                <div className="lg:w-[50%] w-full flex flex-col items-center justify-center gap-16">
                    <div className="flex flex-col lg:items-start w-full gap-5">
                        <p className="font-Cormorant lg:text-[38px] text-[25px] text-center text-black lg:w-[640px]">
                            YOUR STORY IS UNIQUE
                        </p>
                        <p className="font-Roboto font-[400] lg:text-[18px] text-[14px] text-center lg:w-[640px] w-full tracking-wider">
                            Our portfolio represents a harmonious blend of contemporary,
                            documentary, and artistic styles. Whether it’s the stolen
                            glances, the heartfelt vows, or the grandeur of the day, each
                            photograph is thoughtfully composed to encapsulate the emotions
                            and details that make your wedding day truly exceptional.
                        </p>
                    </div>
                    <div
                        className="flex lg:flex-row flex-col lg:items-start lg:justify-start items-center justify-center w-full gap-8">
                        <button
                            onClick={handleOpenModal}
                            className="border border-black flex flex-row items-center justify-center gap-3 rounded-[25px] font-Jost lg:text-[16px] text-[12px] font-[500] pl-[30px] pr-[30px] pt-[15px] pb-[15px] hover:bg-black hover:text-white transition duration-300"
                        >
                            <CiCalendar size={25}/> CHECK YOUR DATE
                        </button>
                        <Link to={"portfolio"}>
                            <button
                                className="border-b border-black font-Jost text-[16px] font-[500] pl-[25px] pr-[25px] pt-[15px] pb-[15px] hover:bg-black hover:text-white transition duration-300">
                                EXPLORE PORTFOLIO
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            {/*Video*/}
            {/*<section id="video" className="w-screen lg:h-[100vh] h-[100vh]   relative lg:p-0 p-3">*/}
            {/*    <div className="absolute  inset-0 w-full  h-full overflow-hidden">*/}
            {/*        <iframe*/}
            {/*            title={"Youtube Foreverknots Video"}*/}
            {/*            className="absolute top-0 left-0 w-screen h-screen lg:object-cover object-center"*/}
            {/*            src="https://www.youtube.com/embed/udqtXZRUcMs?autoplay=1&mute=1&controls=0&loop=1&playlist=udqtXZRUcMs"*/}
            {/*            allow="autoplay; encrypted-media"*/}
            {/*            allowFullScreen*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div*/}
            {/*        className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center text-center text-white px-4">*/}
            {/*        <h2 className="text-3xl sm:text-5xl font-bold tracking-tight lg:mt-48">Timeless Moments Captured</h2>*/}
            {/*        <p className="lg:max-w-[600px] text-lg sm:text-xl mt-4 text-muted-foreground">*/}
            {/*            Experience the beauty and emotion of your wedding day through our cinematic videography.*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*</section>*/}


            {/* Other Photography Services */}
            {/*<PhotographyServices/>*/}
            <div className="flex flex-col lg:items-center items-start space-y-4 p-4">
                <h2 className="lg:text-3xl text-2xl font-bold">Our Photography Services</h2>
                <p className="font-Jost lg:text-[20px] text-[18px]">
                    Explore our range of professional photography services to capture your special moments.
                </p>
            </div>
            <LayoutGridDemo/>
            <div className="flex flex-col lg:items-center items-start space-y-4 p-4">
                <h2 className="lg:text-3xl text-2xl font-bold">What Our Clients Say</h2>
                <p className="ont-Jost lg:text-[20px] text-[18px]">
                    Hear from some of our happy clients about their experience working with ForeverKnots.
                </p>
            </div>
            <InfiniteMovingCardsDemo/>
            {/*<CardHoverEffectDemo/>*/}
            <Modal show={showModal} onClose={handleCloseModal}/>
        </div>
    );
};

export default Home;
