"use client";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutGrid } from "../Layout/Layout-Grid";
import service1 from "../../assets/PhotoGraphyServices/bridal.jpg";
import service2 from "../../assets/PhotoGraphyServices/prewedding.jpg";
import service3 from "../../assets/PhotoGraphyServices/candid.jpg";
import service4 from "../../assets/PhotoGraphyServices/maternity.jpg";
import service5 from "../../assets/PhotoGraphyServices/riceCeremony.jpg";
import service6 from "../../assets/PhotoGraphyServices/birthday.jpg";

export function LayoutGridDemo() {
    return (
        <div className="h-screen flex items-center justify-center py-20 w-screen">
            <LayoutGrid cards={cards} />
        </div>
    );
}

const SkeletonOne = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/services/wedding');
    };

    return (
        <div>
            <p className="font-bold md:text-4xl text-xl text-white">
                Wedding Photography
            </p>
            <p className="font-normal text-base text-white"></p>
            <p className="font-Jost lg:text-[20px] text-[15px] my-4 max-w-lg text-neutral-200">
                Your wedding day is one of the most important days of your life. We ensure every precious moment is
                captured with elegance and emotion.
            </p>
            <button
                onClick={handleClick}
                className="inline-flex h-9 items-center justify-center rounded-md bg-white px-4 py-4 text-[18px] font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                Learn More
            </button>
        </div>
    );
};

const SkeletonTwo = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/services/prewedding');
    };

    return (
        <div>
            <p className="font-bold md:text-4xl text-xl text-white">
                Pre Wedding Photography
            </p>
            <p className="font-normal text-base text-white"></p>
            <p className="font-Jost lg:text-[20px] text-[15px] my-4 max-w-lg text-neutral-200">
                Capture the love and excitement leading up to your big day with our pre-wedding shoots. We work with you
                to create stunning images that tell your love story.
            </p>
            <button
                onClick={handleClick}
                className="inline-flex h-9 items-center justify-center rounded-md bg-white px-4 py-4 text-[18px] font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                Learn More
            </button>
        </div>
    );
};

const SkeletonThree = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/services/candid');
    };

    return (
        <div>
            <p className="font-bold md:text-4xl text-xl text-white">
                Candid Wedding Photography
            </p>
            <p className="font-normal text-base text-white"></p>
            <p className="font-Jost lg:text-[20px] text-[15px] my-4 max-w-lg text-neutral-200">
                Get the frames of real happiness and flow of emotions with our most demanded candid wedding photography.
            </p>
            <button
                onClick={handleClick}
                className="inline-flex h-9 items-center justify-center rounded-md bg-white px-4 py-4 text-[18px] font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                Learn More
            </button>
        </div>
    );
};

const SkeletonFour = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/services/rice-ceremony');
    };

    return (
        <div>
            <p className="font-bold md:text-4xl text-xl text-white">
                Rice Ceremony Photography
            </p>
            <p className="font-normal text-base text-white"></p>
            <p className="font-Jost lg:text-[20px] text-[15px] my-4 max-w-lg text-neutral-200">
                Celebrating the joy of your child's first rice-eating ceremony? Our team is dedicated to capturing the
                cultural and emotional essence of this significant event.
            </p>
            <button
                onClick={handleClick}
                className="inline-flex h-9 items-center justify-center rounded-md bg-white px-4 py-4 text-[18px] font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                Learn More
            </button>
        </div>
    );
};

const SkeletonFive = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/services/maternity');
    };

    return (
        <div>
            <p className="font-bold md:text-4xl text-xl text-white">
                Maternity & Baby Photography
            </p>
            <p className="font-normal text-base text-white"></p>
            <p className="font-Jost lg:text-[20px] text-[15px] my-4 max-w-lg text-neutral-200">
                Celebrate the journey to parenthood with our maternity shoots. We create beautiful, intimate portraits
                that reflect the anticipation and joy of this special time.
            </p>
            <button
                onClick={handleClick}
                className="inline-flex h-9 items-center justify-center rounded-md bg-white px-4 py-4 text-[18px] font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                Learn More
            </button>
        </div>
    );
};

const SkeletonSix = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/services/birthday');
    };

    return (
        <div>
            <p className="font-bold md:text-4xl text-xl text-white">
                Birthday Photography
            </p>
            <p className="font-normal text-base text-white"></p>
            <p className="font-Jost lg:text-[20px] text-[15px] my-4 max-w-lg text-neutral-200">
                Transforming your wedding into a captivating love tale, let's capture and cherish every enchanting moment together.
            </p>
            <button
                onClick={handleClick}
                className="inline-flex h-9 items-center justify-center rounded-md bg-white px-4 py-4 text-[18px] font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                Learn More
            </button>
        </div>
    );
};

const cards = [
    {
        id: 1,
        content: <SkeletonOne />,
        className: "",
        thumbnail:service1
    },
    {
        id: 2,
        content: <SkeletonTwo />,
        className: "",
        thumbnail:service2,
    },
    {
        id: 3,
        content: <SkeletonThree />,
        className: "",
        thumbnail: service3,
    },
    {
        id: 4,
        content: <SkeletonFour />,
        className: "",
        thumbnail:service5,
    },
    {
        id: 5,
        content: <SkeletonFive />,
        className: "",
        thumbnail:service4,
    },
    {
        id: 6,
        content: <SkeletonSix />,
        className: "",
        thumbnail:service5,
    },
];
