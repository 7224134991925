import React from "react";
import logo from "../../assets/logo.png"; // Path to your logo
import "./loader.css"

const Loader = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="relative flex items-center justify-center">
                <img src={logo} alt="Logo" className="absolute lg:h-[40px]" />
                <div className="loader"></div>
            </div>
        </div>
    );
};

export default Loader;
