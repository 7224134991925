// Slider.js
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { EffectFade, Autoplay, Pagination } from 'swiper/modules';
import { useImageContext } from '../../context/ImageContext';
import './styles.css';

const Slider = () => {
    const { images, imageCache, loading } = useImageContext();
    const [scaleClass, setScaleClass] = useState('');

    useEffect(() => {
        setScaleClass('scale-once');
        const timer = setTimeout(() => {
            setScaleClass('');
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    const coverImages = imageCache.coverImagesCache.length > 0
        ? imageCache.coverImagesCache.map(img => img.src)
        : images.cover_images;

    return (
        <Swiper
            spaceBetween={30}
            effect={'fade'}
            centeredSlides={true}
            loop={true}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, EffectFade]}
            className="mySwiper"
        >
            {coverImages.map((imageUrl, index) => (
                <SwiperSlide key={index} className="swiper-slide-custom">
                    <img className={`slide-img ${scaleClass}`} src={imageUrl} alt={`Cover  ${index + 1}`} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default Slider;
