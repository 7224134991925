
import LightGallery from 'lightgallery/react';


const Gallery = () => {

  const dynamicEl = [
    {
      src: 'https://foreverknots.s3.eu-north-1.amazonaws.com/368229236_193352880416460_5894480636005709621_n.jpg',
      responsive: '...',
      thumb: 'Arnab Didi',
      subHtml: `...`,
    },
    {
      src: 'https://foreverknots.s3.eu-north-1.amazonaws.com/431791206_317348874683526_80863799565050064_n.jpg',
      responsive: '...',
      subHtml: `...`,
    },
    // Add more placeholder images as needed
  ];

  return  <>
    <LightGallery
        dynamic={true}
        dynamicEl={dynamicEl}
    />
  </>;
};

export default Gallery;
