// ImageContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { BASEAPI } from '../Utils/BASEAPI';

const ImageContext = createContext();

export const useImageContext = () => useContext(ImageContext);

export const ImageProvider = ({ children }) => {
    const [images, setImages] = useState({
        cover_images: [],
        gallery_images: [],
        BirthdayPhotography:[],
        CandidPhotography:[],
        MaternityPhotography:[],
        PreWeddingPhotography:[],
        RiceCeremonyPhotography:[],
        WeddingPhotography:[],

    });
    const [loading, setLoading] = useState(true);
    const [imageCache, setImageCache] = useState({
        coverImagesCache: [],
        galleryImagesCache: [],
        birthdayImagesCache:[],
        candidImagesCache:[],
        maternityImagesCache:[],
        preweddingImagesCache:[],
        riceCeremonyImagesCache:[],
        weddingImagesCache:[],
    });

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(`${BASEAPI}/v2/get-all-images`);
                setImages(response.data);

                // Pre-render images and store in cache
                const coverImagesCache = response.data.cover_images.map(src => {
                    const img = new Image();
                    img.src = src;
                    return img;
                });

                const galleryImagesCache = response.data.gallery_images.map(src => {
                    const img = new Image();
                    img.src = src;
                    return img;
                });
                const birthdayImagesCache = response.data.BirthdayPhotography.map(src => {
                    const img = new Image();
                    img.src = src;
                    return img;
                });
                const candidImagesCache = response.data.CandidPhotography.map(src => {
                    const img = new Image();
                    img.src = src;
                    return img;
                });
                const maternityImagesCache = response.data.MaternityPhotography.map(src => {
                    const img = new Image();
                    img.src = src;
                    return img;
                });
                const preweddingImagesCache = response.data.PreWeddingPhotography.map(src => {
                    const img = new Image();
                    img.src = src;
                    return img;
                });
                const riceCeremonyImagesCache = response.data.RiceCeremonyPhotography.map(src => {
                    const img = new Image();
                    img.src = src;
                    return img;
                });
                const weddingImagesCache = response.data.WeddingPhotography.map(src => {
                    const img = new Image();
                    img.src = src;
                    return img;
                });

                setImageCache({ coverImagesCache, galleryImagesCache,birthdayImagesCache,candidImagesCache,maternityImagesCache,preweddingImagesCache,riceCeremonyImagesCache,weddingImagesCache });
            } catch (error) {
                console.error('Error fetching images:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, []);

    return (
        <ImageContext.Provider value={{ images, imageCache, loading }}>
            {children}
        </ImageContext.Provider>
    );
};
