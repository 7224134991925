import React, { useState } from 'react';
import axios from 'axios';
import {useToast} from "@chakra-ui/react";
import {BASEAPI} from "../../Utils/BASEAPI";

const Modal = ({ show, onClose }) => {
    const toast = useToast({position:"top"});
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        date: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            toast({
                title: 'Sending...',
                status: 'info',
                duration: 2000,
                isClosable: true,
            });
            const response = await axios.post(`${BASEAPI}/v2/query-form`, formData);
            console.log('Form submitted:', response.data);
            toast({
                title: 'Request Send Successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            onClose(); // Close the modal after submission
        } catch (error) {
            toast({
                title: 'Error sending response',
                description: error.message || 'An error occurred.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            console.error('Error submitting form:', error);
            // Handle error as needed (e.g., show error message)
        }
    };

    if (!show) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg max-w-md w-full">
                <h2 className="text-xl mb-4">Check Your Date</h2>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        className="border border-gray-300 p-2 rounded"
                        required
                    />
                    <input
                        type="text"
                        name="email"
                        placeholder="Your Email id"
                        value={formData.email}
                        onChange={handleChange}
                        className="border border-gray-300 p-2 rounded"
                        required
                    />
                    <input
                        type="date"
                        name="date"
                        value={formData.date}
                        onChange={handleChange}
                        className="border border-gray-300 p-2 rounded"
                        required
                    />
                    <button
                        type="submit"
                        className="border border-black rounded-[25px] font-Jost text-[14px] font-[500] pl-[30px] pr-[30px] pt-[15px] pb-[15px] hover:bg-black hover:text-white transition duration-300"
                    >
                        Submit
                    </button>
                </form>
                <button
                    className="mt-4 border border-black rounded-[25px] font-Jost text-[14px] font-[500] pl-[30px] pr-[30px] pt-[15px] pb-[15px] hover:bg-black hover:text-white transition duration-300"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default Modal;
