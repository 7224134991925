import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Contactus from './pages/Contactus';
import Home from './pages/Home';
import About from './pages/About';
import Gallery from './pages/Gallery';
import Navbar from './components/Navbar';
import Portfolio from './pages/Portfolio';
import Footer from './components/Footer';
import Services from "./pages/Services";
import {ImageProvider} from "./context/ImageContext";

function App() {
    const location = useLocation();

    return (
        <div className="flex min-h-screen flex-col items-center overflow-hidden scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100">
            <ImageProvider>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="services/:serviceId" element={<Services />} />
                <Route path="about" element={<About />} />
                <Route path="contact-us" element={<Contactus />} />
                <Route path="gallery" element={<Gallery />} />
                <Route path="portfolio" element={<Portfolio />} />
            </Routes>
            {location.pathname !== '/portfolio' && <Footer />}
            </ImageProvider>
        </div>
    );
}

export default App;
