import React from "react";
import Logo from "../assets/logo.png";
import MenuDrop from "./MenuDrop";
import { Link } from "react-router-dom";

const Navbar = () => {

  return (
    <div className="flex absolute z-10 lg:flex-row w-11/12 max-w-maxContent items-center justify-between  lg:h-[56px] h-[65px] mt-1 p-6   lg:border-b lg:border-1 lg:border-white md:border-b md:border-1 md:border-white ">
      <div className="flex lg:flex-row items-center gap-2 lg:w-[50%] md:w-[50%] w-[80%]">
        <Link to={"/"} className={"hidden lg:block md:block"}>
          <img src={Logo} className="lg:h-[40px]" alt="Website logo" />
        </Link>
        <Link to={"/"}>
          <p className="font-Urbanist lg:font-[700] md:font-[700] font-[600] lg:text-xl text-[15px] text-white ml-7">
            Forever Knots
          </p>
        </Link>
      </div>
      <div className="flex gap-3 font-Urbanist flex-col lg:w-[50%] md:w-[50%] items-end font-Jost">
        {/* Conditionally render menu items for large and medium screens */}
        <div className="hidden lg:block md:block w-[40%]">
          <ul className="text-white text-[19px] flex flex-row w-full justify-around font-[400]">
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"portfolio"}>Portfolio</Link>
            </li>
            <li>
              <Link to={"contact-us"}>Contact Us</Link>
            </li>
            <li>
              <Link to={"about"}>About</Link>
            </li>
          </ul>
        </div>

        {/* Conditionally render MenuDrop for mobile devices */}
        <div className="lg:hidden md:hidden block  ">
          <MenuDrop />

        </div>
      </div>
    </div>
  );
};

export default Navbar;
