import React from "react";
import GalleryComponent from "../components/Portfolio/GalleryComponent";

const Portfolio = () => {
    return <div className={"max-w-screen flex w-screen   flex-col items-center justify-center  gap-0 "}>
        <div className={"bg-black w-full h-[80px] "}></div>
        <GalleryComponent/>
    </div>;
};

export default Portfolio;
