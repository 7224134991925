import React, { useState } from 'react';
import { MdOutlineEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { BiSupport } from "react-icons/bi";
import {BASEAPI} from "../Utils/BASEAPI";
import axios from "axios";
import {useToast} from "@chakra-ui/react";

const Contactus = () => {
    const toast = useToast({position:"top"});
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        location: '',
        date: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        toast({
            title: 'Sending...',
            status: 'info',
            duration: 2000,
            isClosable: true,
        });

        // API call
        try {
            const response =await axios.post(`${BASEAPI}/v2/contact-form`, formData);
            console.log("Response:", response);
            if(response.data.status !== 201){
                toast({
                    title: 'Error sending response',
                    description:'An error occurred.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
            toast({
                title: 'Request Send Successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setFormData({ name: '',
                email: '',
                phone: '',
                location: '',
                date: '',
                message: '',})

        } catch (error) {
            toast({
                title: 'Error sending response',
                description: error.message || 'An error occurred.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            console.error('Error:', error);
        }
    };

    return (
        <div className={"h-full w-screen bg-[#F2F0EC]"}>
            <div className="font-[sans-serif]">
                <div className="bg-gradient-to-r from-blue-700 to-blue-300 w-full h-60">
                    <img src="https://foreverknots.s3.eu-north-1.amazonaws.com/uploads/1720267236-358082122_166688453082903_4207479787538989106_n.jpg" alt="Wedding"
                         className="w-full h-full object-cover"/>
                </div>

                <div className="-mt-28 mb-6 px-4 ">
                    <div className="mx-auto max-w-6xl shadow-md p-8 relative bg-white rounded-md ">
                        <h2 className="lg:text-3xl text-xl font-[600] font-Cormorant  lg:mb-8 mb-2">
                            Let's work together
                        </h2>

                        <form className="mt-8 grid sm:grid-cols-2 gap-6 font-Roboto" onSubmit={handleSubmit}>
                            <div>
                                <label className="text-gray-800 text-sm block mb-2">Your Name</label>
                                <input
                                    type='text'
                                    name='name'
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder='Enter Name'
                                    className="w-full rounded-md py-2.5 px-4 border border-gray-300 text-sm outline-[#007bff]"
                                />
                            </div>
                            <div>
                                <label className="text-gray-800 text-sm block mb-2">Your Email</label>
                                <input
                                    type='email'
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder='Email'
                                    className="w-full rounded-md py-2.5 px-4 border border-gray-300 text-sm outline-[#007bff]"
                                />
                            </div>
                            <div>
                                <label className="text-gray-800 text-sm block mb-2">Your Number</label>
                                <input
                                    type='text'
                                    name='phone'
                                    value={formData.phone}
                                    onChange={handleChange}
                                    placeholder='Phone No.'
                                    className="w-full rounded-md py-2.5 px-4 border border-gray-300 text-sm outline-[#007bff]"
                                />
                            </div>
                            <div>
                                <label className="text-gray-800 text-sm block mb-2">Location</label>
                                <input
                                    type='text'
                                    name='location'
                                    value={formData.location}
                                    onChange={handleChange}
                                    placeholder='Location'
                                    className="w-full rounded-md py-2.5 px-4 border border-gray-300 text-sm outline-[#007bff]"
                                />
                            </div>
                            <div>
                                <label className="text-gray-800 text-sm block mb-2">Date</label>
                                <input
                                    type='date'
                                    name='date'
                                    value={formData.date}
                                    onChange={handleChange}
                                    placeholder='Date'
                                    className="w-full rounded-md py-2.5 px-4 border border-gray-300 text-sm outline-[#007bff]"
                                />
                            </div>

                            <div className="col-span-full">
                                <label className="text-gray-800 text-sm block mb-2">Message</label>
                                <textarea
                                    name='message'
                                    value={formData.message}
                                    onChange={handleChange}
                                    placeholder='Message'
                                    rows="6"
                                    className="w-full rounded-md px-4 border border-gray-300 text-sm pt-3 outline-[#007bff]"
                                ></textarea>
                            </div>

                            <button type='submit'
                                    className="w-max  border-b border-black font-Jost text-[16px] font-[500] pl-[25px] pr-[25px] pt-[15px] pb-[15px] hover:bg-black hover:text-white transition duration-300">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill='#000'
                                     className="mr-2 inline" viewBox="0 0 548.244 548.244">
                                    <path
                                        d="M392.19 156.054 211.268 281.667 22.032 218.58C8.823 214.168-.076 201.775 0 187.852c.077-13.923 9.078-26.24 22.338-30.498L506.15 1.549c11.5-3.697 24.123-.663 32.666 7.88 8.542 8.543 11.577 21.165 7.879 32.666L390.89 525.906c-4.258 13.26-16.575 22.261-30.498 22.338-13.923.076-26.316-8.823-30.728-22.032l-63.393-190.153z"
                                        data-original="#000000"/>
                                </svg>
                                Send Message
                            </button>
                        </form>
                    </div>
                    <div
                        className={"bg-[#F2F0EC] flex lg:flex-row flex-col items-center justify-center w-7xl lg:gap-20 gap-3 mt-10"}>
                        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6 mt-12">
                            <div className="rounded-lg border bg-card text-card-foreground shadow-sm bg-white" data-v0-t="card">
                                <div className="flex flex-col space-y-1.5 p-6">
                                    <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">Call
                                        Us</h3>
                                    <p className="text-sm text-muted-foreground">
                                        Our team is available to answer your questions and provide support.
                                    </p>
                                </div>
                                <div className="p-6">
                                    <div className="flex items-center gap-4">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="w-8 h-8 text-primary"
                                        >
                                            <path
                                                d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                                        </svg>
                                        <div>
                                            <p className="font-medium">+1 (555) 123-4567</p>
                                            <p className="text-muted-foreground">Monday - Friday, 9am - 5pm</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rounded-lg border bg-card text-card-foreground shadow-sm bg-white" data-v0-t="card">
                                <div className="flex flex-col space-y-1.5 p-6">
                                    <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">Email
                                        Us</h3>
                                    <p className="text-sm text-muted-foreground">
                                        Send us a message and we'll get back to you as soon as possible.
                                    </p>
                                </div>
                                <div className="p-6">
                                    <div className="flex items-center gap-4"></div>
                                </div>
                            </div>
                            <div className="rounded-lg border bg-card text-card-foreground shadow-sm bg-white" data-v0-t="card">
                                <div className="flex flex-col space-y-1.5 p-6">
                                    <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">Support
                                        </h3>
                                    <p className="text-sm text-muted-foreground">
                                        Send us a message and we'll get back to you as soon as possible.
                                    </p>
                                </div>
                                <div className="p-6">
                                    <div className="flex items-center gap-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contactus;
